<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Assuming the base completely dissociates in water, what is the <b>pH</b> of a
        <number-value :value="gramConc" unit="\text{g/L}" />
        solution of
        <chemical-latex content="Ba(OH)2(aq)?" />
      </p>

      <calculation-input
        v-model="inputs.pH"
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question139',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        pH: null,
      },
    };
  },
  computed: {
    gramConc() {
      return this.taskState.getValueBySymbol('gramConc').content;
    },
  },
};
</script>
